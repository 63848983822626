import isEqual from 'lodash/isEqual'

import { jsonSafeParse } from '../helpers/string-helper'

import { IDataCenterConfig } from '../types/common'

class EngynDataCenter {
  static storageKey = 'engynCurrentDataCenter'

  static getCurrentDataCenter(): IDataCenterConfig {
    return jsonSafeParse(localStorage.getItem(this.storageKey)) || {}
  }

  static setCurrentDataCenter(dataCenterConfig: IDataCenterConfig): {
    webAppChanges: boolean
  } {
    if (!dataCenterConfig) {
      return { webAppChanges: false }
    }

    const oldWebAppVersions = this.getCurrentWebAppVersions()

    localStorage.setItem(this.storageKey, JSON.stringify(dataCenterConfig))

    const newWebAppVersions = dataCenterConfig?.webapp

    return {
      webAppChanges:
        newWebAppVersions && !isEqual(oldWebAppVersions, newWebAppVersions),
    }
  }

  static getCurrentWebAppVersions() {
    return this.getCurrentDataCenter()?.webapp
  }

  static updateWebAppVersions(webAppVersions: IDataCenterConfig['webapp']) {
    const currentDataCenter = this.getCurrentDataCenter()

    return this.setCurrentDataCenter({
      ...currentDataCenter,
      webapp: webAppVersions,
    })
  }
}

export default EngynDataCenter
